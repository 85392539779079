import React, { useState,useEffect,useRef } from "react";
import { connect, useDispatch } from "react-redux";
import VoucherIcon from "../../assets/img/register/voucher.jpeg"
import ConfirmationModal from "../../components/voucher/ConfirmationModal";
import Voucher from "../../store/actions/voucher/index";
import SuccessResponse from "../../components/voucher/SuccessResponse";
import FailedResponse from "../../components/voucher/FailedResponse";
import LogoHeader from "../../assets/img/landing-page/logo_biru_2.png"
import Loader from "../../components/commons/Loader";
import Barcode from "react-barcode";

const Index = ({action,data, match, redeem_data, redeem_error, redeem_pending}) => {
     const [redeemModal, setRedeemModal] = useState()
     const [dataVoucher, setVoucher] = useState()
     const [showDataVoucher, setShowDataVoucher] = useState()
     const [showSuccess, setShowSuccess] = useState()
     const [showFailed, setShowFailed] = useState()
     const [failedMessage, setFailedMessage] = useState()
     const [availableVoucher, setAvailableVoucher] = useState()
     const [failedAvailableVoucher, setFailedAvailableVoucher] = useState()
     const dispatch = useDispatch();

     const ids = match?.params?.id 
      
     const handleRedeem = () => {
         setRedeemModal(!redeemModal)
     }
    const handleConfirmRedeem = () => {
        let id = {
                id: ids,
                barcode: dataVoucher.id_barcode,
                name: dataVoucher.nama_pemenang,
                no_telp: dataVoucher.no_whatsapp,
                }
        new Promise((resolve)=>{
            dispatch(Voucher.redeem_voucher(resolve, id))
        })
        .then(res => {
           console.log(res.data)
           if (res.data?.data){
            setShowSuccess(true);
            setShowDataVoucher(false);
            handleRedeem();
           }else{
               setFailedMessage(res.data.message)
               handleFailed();
           }
          
        })
    }
    useEffect(() => {
      checkAvailableVoucher()
      clearCacheData()
      cekVoucher();
    }, [])

    // Function to clear complete cache data
  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

    const handleFailed = () => {
        setShowDataVoucher(false);
        setShowFailed(true)
       handleRedeem()
    }
    const cekVoucher = () => {
        let id = {id: ids}
        new Promise((resolve)=>{
            dispatch(Voucher.check_voucher(resolve, id))
        })
        .then(res => {
            setVoucher(res.data)
            setShowDataVoucher(true)
        })
    }

    const checkAvailableVoucher = () => {
      let id = {
                id: ids
              }
      new Promise((resolve)=>{
          dispatch(Voucher.check_available_voucher(resolve, id))
      })
      .then(res => {
         if (res.data?.data){
            setAvailableVoucher(true)
         }else{
            setAvailableVoucher(false)
            setFailedAvailableVoucher(res.data?.message)
         }
         showLoadingSection()
        
      })
    }

    const [showLoading, setShowLoading] = useState(true);
    const showLoadingSection = () => {
      setShowLoading(!showLoading)
    }

        return(
            <div style={{height:"100%",width:"inherit"}}>
            {showLoading ? 
              <Loader />
            :(
              <div>

                <div className="header-logo">
                   <img className="logo" src={LogoHeader}></img>
                </div>
   
                 {availableVoucher ? (
                   <div>
                     {dataVoucher && showDataVoucher &&  
                     <div className="redeem">
                         <div className="voucher-id" >
                             <img src={process.env.REACT_APP_API_URL + dataVoucher.barcode_image} style={{maxHeight:200,maxWidth:"100%"}} />
                             <Barcode value={dataVoucher.id_barcode} height={45} width={2.97}/>
                         </div>
                         <div className="caption-redeem">
                           <h3><b>Redeem Voucher</b></h3>
                           <p>Pastikan yang melakukan redeem kode voucher ini adalah pihak kasir agar voucher Anda tidak hangus</p>
                         </div>
                         
                         <button className="button danger" onClick={()=>handleRedeem()}>Redeem</button>
                     </div> 
                     }
                     {showSuccess &&
                       <SuccessResponse></SuccessResponse>
                     } 
                     {showFailed && redeem_error && 
                       <FailedResponse message={failedMessage}></FailedResponse>
                     }
                   </div>
                 ):(
                   <FailedResponse message={failedAvailableVoucher}></FailedResponse>
                 )}
              </div>

            )}

              <ConfirmationModal 
                show={redeemModal}
                toggle={handleRedeem}
                confirm={handleConfirmRedeem}
                // data={dataPatientRow}
                icon="info"
                isConfirm={"Lanjut"}
                message = {"Apakah Anda yakin akan melakukan redeem? Voucher Anda akan hangus setelah di redeem"}
              ></ConfirmationModal>
            </div>
          )
   
    
}
const mapStateToProps = ({ voucher: { redeem_pending, redeem_data, redeem_error } }) => {
    return { redeem_pending, redeem_data, redeem_error };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};
export default connect(mapStateToProps, mapDispatchToProps)(Index)